<template>
    <div class="mt-3">
        <b-row>
            <b-col cols="6">
                    <b-card-text>Raffle Start Date:</b-card-text>
                    <b-card-title class="font-weight-bolder">{{details.startdate}}</b-card-title>
            </b-col>
            <b-col cols="6">
                <b-card-text>Ticket Cost:</b-card-text>
                    <b-card-title class="font-weight-bolder">{{details.price}} {{details.typeCoin==1?'Crowns':'Goblets'}}</b-card-title>
            </b-col>
                 <b-col cols="6">
                <b-card-text v-if="buy">Raffle Ends in:</b-card-text>
                 <b-card-text v-if="!buy">Raffle Due Date:</b-card-text>
                 <b-card-title class="font-weight-bolder">{{duetime}}</b-card-title>
            </b-col>
            <b-col cols="6">
                <b-card-text>Tickets Remaining:</b-card-text>
                 <b-card-title class="font-weight-bolder" v-if="buy">{{stock}}</b-card-title>
                 <b-card-title class="font-weight-bolder text-danger" v-if="!buy && stock>0">{{stock}}</b-card-title>
                 <b-card-title class="font-weight-bolder text-danger" v-if="stock==0">Soldout</b-card-title>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import {BRow,BCol,BCardTitle,BCardText} from "bootstrap-vue"
import UserService from "@/services/UserService"
export default {
    props:["details"],
    components:{BRow,BCol,BCardTitle,BCardText},
    data(){
        return{
            duetime:null,
            buy:true,
            duedate:null,
            stock:null
        }
    },
    mounted(){
        this.getRemainingTime()
    },
    watch:{
        duetime:function(){
            const vm=this;
            setTimeout(async function(){
                await vm.getRemainingTime();
            },1000)
        }
    },
    methods:{
        getRemainingTime:async function(){
            const ti=(await UserService.getRemainingTime(JSON.parse(localStorage.getItem("auth")).discordId,this.details.id)).data
          //  console.log(ti);
            this.duetime=ti.hour
            this.buy=ti.buy
            this.stock=ti.storeitem
             this.$root.$emit("buy", [this.buy,ti?.winner]);
        }
    }
}
</script>