<template>
  <div>
    <b-card>
      <b-img rounded :src="image" fluid-grow alt="Responsive image" />
      <b-card-body>
        <div class="text-center">
          <b-card-title> Tickets Purchased</b-card-title>
        </div>
           <app-collapse
      accordion
      type="margin"
    >
    <div   v-for="(ticket,i) in tickets" :key="i">
            <app-collapse-item :title="'Ticket '+(i+1)">{{ticket.ticket}}</app-collapse-item>
    </div>

           </app-collapse>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  props: ["image", "tickets"],
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardTitle,
    BCardText,AppCollapse,AppCollapseItem
  },
  data() {
    return {
    };
  },
};
</script>