<template>
  <div class="mt-3">
    <div class="row border p-1" style="border-radius: 10px">
      <div class="col-4"><h6 class="text-left">Ranking</h6></div>
      <div class="col-4"><h6 class="text-center">Username</h6></div>
      <div class="col-4"><h6 class="text-right">Tickets Bought</h6></div>
    </div>
    <ul class="container mt-1">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area"
        v-if="participants.length > 0"
      >
        <b-list-group flush v-for="(participant, i) in participants" :key="i">
          <b-list-group-item
            class="d-flex justify-content-between align-items-center p-1"
            hover
          >
            <span>{{ i + 1 }}</span>
            <span>{{ participant.username }}</span>
            <b-badge variant="light-success" pill class="badge-round">
              {{ participant.ticketAmount }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </vue-perfect-scrollbar>
    </ul>
  </div>
</template>
<script>
import { BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import UserService from "@/services/UserService";
export default {
  props: ["details"],
  components: { BListGroup, BListGroupItem, BBadge, VuePerfectScrollbar },

  data() {
    return {
      participants: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    this.getParticipants();
  },
  methods: {
    getParticipants: async function () {
      const p = (
        await UserService.getParticipants(
          JSON.parse(localStorage.getItem("auth")).discordId,
          this.details.id
        )
      ).data;
      this.participants = p;
      /* this.participants = [{"ticketAmount":3,"username":"DavooSOL"},{"ticketAmount":3,"username":"DavooSOL"},{"ticketAmount":3,"username":"DavooSOL"},
      {"ticketAmount":3,"username":"DavooSOL"},{"ticketAmount":3,"username":"DavooSOL"},{"ticketAmount":3,"username":"DavooSOL"},{"ticketAmount":3,"username":"DavooSOL"},
      {"ticketAmount":3,"username":"DavooSOL"}];*/
    },
  },
};
</script>
<style>
.ps {
  height: 200px;
}
</style>
